import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { FormStyles } from '../../styles/Components/Forms/ConversionForm';
import { FormSpinner } from '../../utils/Spinner';
import setValuesFromParams from './utils/setValuesFromParams';

const FormNew = props => {
  const { formId, title, subtitle, sfcid, variant } = props;

  return (
    <FormStyles variant={variant}>
      <h4 className="form-title">{title}</h4>
      <p className="form-subtitle">{subtitle}</p>
      <div id="hub-form">
        <HubspotForm
          formId={formId}
          loading={<FormSpinner />}
          onFormReady={setValuesFromParams}
          portalId="5424293"
          sfdcCampaignId={sfcid}
        />
      </div>
    </FormStyles>
  );
};

export default FormNew;
