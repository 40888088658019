import styled from 'styled-components';

import { atMinWidth } from '../../atoms/breakpoints';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';
import shadow from '../../atoms/shadows';

export const FormStyles = styled.div`
  z-index: 1;
  .form-title {
    ${font('display', 'sm', '700')}
    color: #1d2030;
    text-align: center;
    margin-bottom: 8px;
  }

  .form-subtitle {
    ${font('text', 'md', '400')}
    color: #6f7283;
    margin-bottom: 24px;
    text-align: center;
  }

  #hub-form {
    fieldset {
      border: none !important;
      display: block;
      justify-content: space-between;
      padding: 0;
      min-width: 100%;
      &.form-columns-2 div.hs-form-field {
        width: 100%;
        ${atMinWidth.md`
          width: 48%;
        `}
      }
      &.form-columns-1 div.hs-form-field {
        width: 100% !important;
      }
      ${atMinWidth.md`
        display: flex;
      `}
      .hs-form-field {
        label {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 140%;
          color: #171e2a !important;
          .hs-form-required {
            color: ${colors.error[300]};
          }
          @media (max-width: 991px) {
            margin-bottom: 0 !important;
          }
          &.hs-error-msg {
            display: block;
          }
          @media (max-width: 767.98px) {
            text-align: left;
            width: 100%;
          }
        }
        div.input {
          max-width: 100% !important;
          margin-bottom: 16px !important;
          margin-right: 0 !important;
          input {
            background: ${colors.base.white};
            border: 1px solid #c0c3d1;
            box-sizing: border-box;
            ${shadow('xs')}
            border-radius: 8px;
            padding: 12px 16px;
            width: 100%;
            height: 44px;
            font-size: 18px;
            line-height: 150%;
            &:focus {
              border-color: ${colors.primary[300]};
            }
            &.error {
              border-color: ${colors.error[300]};
            }
          }
          select {
            border: 1px solid ${colors.gray[300]};
            border-radius: 8px;
            padding: 12px 16px;
            width: 100%;
            height: 47px;
            font-size: 18px;
            line-height: 150%;
            background: ${colors.base.white};
            ${shadow('xs')}
            &:focus {
              border-color: ${colors.primary[300]};
            }
          }
          @media (max-width: 991px) {
            margin-bottom: 8px;
          }
        }
        .hs-error-msgs {
          list-style-type: none;
          padding-top: 8px;
          margin-bottom: 0;
          & label {
            font-size: 14px;
            color: #dc3545 !important;
            margin-top: -12px;
          }
        }
      }
    }
    .hs-submit {
      padding: 0 !important;
      margin: 0 !important;
      input {
        padding: 10px 30px;
        font-weight: 700 !important;
        background-color: ${props =>
          props.variant === 'Form-V2' || props.variant === 'Form-Only'
            ? colors.primary[500]
            : 'var(--success-color)'};
        border-radius: 6px !important;
        border: none !important;
        width: 100% !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 18px !important;
        line-height: 1.5 !important;
        letter-spacing: -0.27px !important;
        color: #f7f9fc !important;
        margin-top: 10px;
        transition: all 150ms ease;
        transform: perspective(1px) translateZ(0);
        transition-timing-function: cubic-bezier(0.47, 2.02, 1, 0.79);
        transition-duration: 0.15s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .hs_error_rollup {
      & ul {
        list-style-type: none;
        & label {
          font-size: 16px;
          color: ${colors.error[300]};
        }
      }
    }
    ul {
      margin: 0;
    }
  }
`;
